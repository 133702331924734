import React, {useState, useEffect} from 'react';
import { useLocation} from 'react-router-dom';
import { useUserContext } from "../../contexts";
import { getProducts, Analytics } from '../../lib/api';

import { Header } from '../Header';
import Cta from '../Plans/Cta';
import { Faq } from '../Faq';
import { Footer } from '../Footer';
import { Spinner } from '../Spinner';
import productsMetadata from '../../constants/plans';

import './Enterprise.scss';

const Enterprise = ({}) => {
    //Custom Hooks
    const {isAuthenticating, user} = useUserContext();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    //State to control the payment provider
    const [provider, setProvider] = useState(query.get('prov') && query.get('prov') === 'p'?'paypal':'stripe');
    //State to control/filter what plans to show Month/Year (for the moment)
    const [planPeriod, setPlanPeriod] = useState(query.get('freq') && query.get('freq') === 'm'?'Month':'Year');

    const [showLoading, setShowLoading] = useState(false);
    const [userCurrentPrice, setUserCurrentPrice] = useState(0);
    const [products, setProducts] = useState([]);
    const [showFeatures, setShowFeatures] = useState([]);

    const toggleFeatures = (index) => {
        let newFeatures = [...showFeatures];
        if(newFeatures.includes(index)){
            newFeatures = newFeatures.filter(f => f !== index);
        }else{
            newFeatures.push(index);
        }
        setShowFeatures(newFeatures);
    };


    useEffect(() => {
        
        fetchProducts().then(data => {
            console.log("Fetched plans", data);
            setProducts(filterProducts(data));
        })
        .catch(e => {
            console.debug("error getting enterprise plans", e);
        });
    }, []);
    const fetchProducts = async () => {

        let [enterpriseUpgrades] = await Promise.all([
            getProducts({campaign: 'enterprise-only'}),
        ]);

        enterpriseUpgrades = enterpriseUpgrades.filter(p => p.name.toLowerCase() === 'enterprise').map(p => p.plans).flat();

        return [ ...enterpriseUpgrades];
    };
    const filterProducts = (data) => {

        let newPlans = data.map((plan) => ({
            ...plan,
            metadata: productsMetadata.find(pm => pm.name.toLowerCase() === `enterprise plus ${planNameToUsers(plan.name)}`),
        }));
        
        console.log({newPlans});
        return newPlans;
    };

    const searchPlan = plan_id => {
        let plan = null;
        console.log(products);
        Object.keys(products).map(k => products[k]).forEach(prod => {
            if(+prod.id === +plan_id){
                plan = prod;
                return;
            }
        });
        return plan;
    };

    const handleCta = plan_id =>{
        console.log("selected plan", plan_id);
        //setShowLoading(true);
        try{
            const plan = searchPlan(plan_id);
            console.log("plan", plan);
            Analytics.addToCart(plan.price);
        }catch(e){
            console.log("analytics error", e);
        }
        setTimeout(() => document.location.href=`/signup/${plan_id}/${provider==='stripe'?'s':'p'}?c=enterprise-only`, 300);
    };

    const planNameToUsers = (name) => {
        if(name.toLowerCase().includes('10')){
            return 10;
        }
        if(name.toLowerCase().includes('20')){
            return 20;
        }
        return 5;
    }
    return (
        <>
            <Header minimal={true} />
            <div className={`enterprise`}>
                <h1 className="title m-b-48">ENTERPRISE PLANS</h1>
                
                {(showLoading || isAuthenticating) && 
                <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', width: '100%', height: 'calc(80vh - 300px)'}}>
                    <Spinner style={{position: 'relative'}} />
                </div>
                }
                {userCurrentPrice === 0 && 
                <div className="switch m-b-24" id="plan-period-switch">
                    <div className="option" onClick={() => setPlanPeriod('Month')}>Monthly</div>
                    <div className={`bar ${planPeriod=='Month'?'left':'right'}`} onClick={() => setPlanPeriod(planPeriod=='Month'?'Year':'Month')}>
                        <div className="circle"></div>
                    </div>
                    <div className="option"  onClick={() => setPlanPeriod('Year')}>Yearly</div>
                </div>
                }
                <h2 className="subtitle">Pricing Tiers Below</h2>
                <div className={`list ${products.length>0?'visible':''} ${planPeriod == 'Year'?'summarized':''}`}>
                {products.filter(p => userCurrentPrice < p.price && p.periodicity_type===planPeriod).map((plan, plan_index) => (
                    <div className={`plan ${plan.metadata && plan.metadata.accent?'accent':''} ${planPeriod == 'Year' && plan.metadata.name.toLowerCase() === 'pro'?'hidden':''}`} key={plan_index} >
                        <div className='bg_whiter'></div>
                        <div className="name m-b-6 xs:m-b-0">
                            {plan.metadata.display_name}
                        </div>
                        {planPeriod==='Year' &&
                        <div className="old-price">${plan.price}/mo</div>
                        }
                        {planPeriod!=='Year' &&
                        <div className="old-price" style={{textDecoration: 'none'}}>&nbsp;</div>
                        }
                        <div className="price">
                            $
                            <div className="accent">
                                {`${planPeriod==='Year'?Math.ceil(plan.price/12):plan.price}`}
                            </div>
                            {planPeriod == 'Year'?'/mo':''}
                        </div>
                        {planPeriod==='Year' &&
                        <div className="note -m-t-16 xs:-m-t-8">billed ${plan.price} annually</div>
                        }
                        {planPeriod==='Month' &&
                        <div className="note -m-t-16 xs:-m-t-8">per month</div>
                        }
                        <Cta
                            label={user?(user.team.subscription.plan.id === plan.id?'Current':'Upgrade now'):'Get Started'}
                            type={plan.metadata.accent?'accent':''}
                            onClick={() => handleCta(plan.id)}
                            disabled={user && user.team.subscription.plan.id ===plan.id}
                        />
                        <div className={`features sm:m-b-12 ${showFeatures.includes(plan_index)?'visible':'collapsed'} `}>
                            {plan.metadata.features && plan.metadata.features.map((feature, feature_index) => (
                            <div className="item" key={`product-${plan_index}-feature-${feature_index}`}>
                                {feature.variable && 
                                    <div style={{display: 'inline-flex', gap: '4px'}}>
                                        <span>{feature.freeValue} </span>
                                        <span>{feature.name}</span>
                                    </div>
                                }
                                {!feature.variable && 
                                    <>
                                    {feature.link && 
                                    <a href={feature.link} target="_blank">{feature.name}</a>   
                                    }
                                    {!feature.link &&
                                        <>
                                            <span dangerouslySetInnerHTML={{__html:feature.name}} />
                                        </>
                                    }
                                    </>
                                }
                                {feature.tooltip && 
                                    <div className="tooltip">
                                        {feature.tooltip}
                                    </div>
                                }
                            </div> 
                            ))}
                        </div>
                        <div className={`show-features ${showFeatures.includes(plan_index)?`collapsed`:`visible`}`} onClick={() => toggleFeatures(plan_index)}>
                            {!showFeatures.includes(plan_index)?`What's included?`:`Hide details`}
                            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                            </svg>
                        </div>
                    </div>
                ))}
                    <div className={`plan accent `}>
                        <div className='bg_whiter'></div>
                        <div className="name m-b-6 xs:m-b-0">
                            Custom team
                        </div>
                        <div className="old-price" style={{textDecoration: 'none'}}>&nbsp;</div>
                        <div className="price">Unique Price</div>
                        <div className="note -m-t-16 xs:-m-t-8">Meet your team needs</div>
                        <Cta
                            label='Contact us'
                            type={'accent'}
                            onClick={() => document.location.href="mailto:support@productioncrate.com"}
                            disabled={false}
                        />
                        <div className={`features sm:m-b-12 visible `}>
                            <div className="item" >
                                <span ><b>All users</b> you need</span>
                                <div className="tooltip">
                                    Build up your enterprise plan according to your company needs, setting up as many users as you need.
                                </div>
                            </div> 
                            <div className="item" >
                                <span><b>No Download </b>limits</span>
                                <div className="tooltip">
                                    Build up your enterprise plan according to your company needs, setting up as many downloads per day as you need.
                                </div>
                            </div> 
                            <div className="item">
                                <span>Full Access to All Libraries</span>
                                <div className="tooltip">
                                    Access ALL professional assets including VFX, audio, graphics, 3D archives, and plugins from the FootageCrate, SoundsCrate, GraphicsCrate, RenderCrate, and ProductionCrate libraries.
                                </div>
                            </div>
                            <div className="item">
                                <span>Full Access to Plugins</span>
                                <div className="tooltip">
                                    Access ALL plugins, scripts, apps, and extensions from the ProductionCrate software libraries.
                                </div>
                            </div>
                            <div className="item">
                                <span>Team management</span>
                                <div className="tooltip">Admin Access for Team Control to Add or Remove Users</div>
                            </div>
                            <div className="item">
                                <a href="https://www.productioncrate.com/enterprise-extended-license-agreement.html" target="_blank">Extended License</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="switch m-b-120 m-t-48" id="plan-provider-switch">
                    <div className="option" onClick={() => setProvider('paypal')}>Paypal</div>
                    <div className={`bar ${provider=='paypal'?'left':'right'}`} onClick={() => setProvider(provider=='paypal'?'stripe':'paypal')}>
                        <div className="circle"></div>
                    </div>
                    <div className="option"  onClick={() => setProvider('stripe')}>Stripe</div>
                </div>
                <div className="title m-t-48 m-b-16" >HOW IT WORKS?</div>
                <ul className="explanation">
                    <li>Choose the plan that covers your team size. For example, if you have 15 people, select the 20-person plan.</li>
                    <li>You get the full download allowance of your chosen plan, even if you don’t use all the spots. For example, a 15-person team would get all 400 daily downloads from the 20-person plan.</li>
                    <li>You have the flexibility to add or remove users at any time.</li>
                </ul>
                <div className="title m-t-80 m-b-16" >WHY THIS IS GREAT?</div>
                <ul className="explanation">
                    <li>Simple and straightforward pricing, making it easy to scale.</li>
                    <li>Your team can download simultaneously.</li>
                    <li>Your allotted download will provide more flexibility for your team members.</li>
                </ul>
            </div>
            <Faq />
            <Footer />
        </>
    );
}

export default Enterprise;


