import React, {useState, useEffect} from 'react';
import { useUserContext } from "../../contexts";
import { meFn } from '../../lib/api';
import s from './Survey.module.scss';

const Survey = () => {
  //dummy padding
  
  const {user, assetsAvailable, isGettingAssetAvailability} = useUserContext();


  const [visible, setVisible] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  const [answered, setAnswered] = useState(0);
  const [surveyData, setSurveyData] = useState({
    thankYou: 'Thanks for your feedback!',
    frequency: 0.3,
    name: "how_you_found_us",
    flagName: "crate.surveyFlagId1",
    questions: [
      {
        labelAccent: "Hey there!",
        label: "We'd love to know how you found out about us:",
        value: 'how_you_found_us',
        answers: [
          {
            label: "🔎 Google search",
            value: 'google',
            selected: false,
          },
          {
            label: "😁 From a Friend",
            value: 'friend',
            selected: false,
          },
          {
            label: "📖 Our Tutorials",
            value: 'tutorials',
            selected: false,
          },
          {
            label: "📱 Social Media",
            value: 'social-media',
            selected: false,
          },
        ]
      },
    ],
  });
  const [seenSurveys, setSeenSurveys] = useState({});
  
  const shadowClick = () => {
    //console.log("Shadow Clicked");
    setVisible(false);
  };

  const closeClick = () => {
    //console.log("cross Clicked");
    setVisible(false);
  };
  
  //entry.674977585=username&entry.790349041=email&entry.1849155168=plan_id

  /*
  const questionsIds = [
    
  ];
  */
 const formUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSfPp8q1Bj22OaP4qKmsIWzg11UrtkG2Kn0N9La0dQ_DIsaq1A/formResponse';
 const surveyNameId = 'entry.1864451892';
 const availableIds = [
    { questionId: 'entry.1982830785', answerId: 'entry.1053492427' },
    { questionId: 'entry.57395573', answerId: 'entry.686072284' },
    { questionId: 'entry.412179655', answerId: 'entry.2009039441' },
    { questionId: 'entry.837359487', answerId: 'entry.2143974432' },
    { questionId: 'entry.590641382', answerId: 'entry.661817722' }
  ];
 const userIds = {
    username: 'entry.674977585',
    email: 'entry.790349041',
    plan_id: 'entry.1849155168'
 };

  
  //post survey to google forms&sheet
  const submitSurvey = () => {
    // URL del formulario de Google Forms que termina en /formResponse
    if (surveyData.questions.length > 5) {
        console.log("Error can't handle a survey with more than 5 questions.");
        return;
    }
    var googleFormURL = formUrl;

    
    // Crear objeto FormData y añadir los datos
    var formData = new FormData();

    formData.append(surveyNameId, surveyData.name);
    surveyData.questions.forEach((question, qIndex) => {
        formData.append(availableIds[qIndex].questionId, question.value);
        question.answers.forEach((answer, aIndex) => {
            if (answer.selected) {
                formData.append(availableIds[qIndex].answerId, answer.value);
            }
        });
    });
    
    if (user && user.username) {
        formData.append(userIds.username, user.username);
    }
    if (user && user.email) {
        formData.append(userIds.email, user.email);
    }
    if (user && user.team && user.team.subscription && user.team.subscription.plan && user.team.subscription.plan.id) {
        formData.append(userIds.plan_id, user.team.subscription.plan.id);
    }

    //console.log("Submitting survey", formData);
    // Post to GForm url
    return fetch(googleFormURL, {
        method: 'POST',
        body: formData,
        mode: 'no-cors'
    });
  };

  const handleSubmit = () => {
    if (!seenSurveys.hasOwnProperty(surveyData.flagName)){
        submitSurvey();
    }
    setThankYou(surveyData.thankYou);
    //save timestamp in localstorage:
    markSeenSurvey(surveyData.flagName);
  };

  const submitAnswer = (j, i) => {
    //surveyData.questions[j].answers[i].selected = true;
    //console.log("SURVEY", j, i, surveyData);
    setSurveyData(prev => {
        let newData = {...prev};
        newData.questions[j].answers[i].selected = true;

        return newData;
    });
    setAnswered(prev => prev+1);
  };
  

  const loadSeenSurveys = (localUser) => {
    if(!localUser){
        return;
    }
    let seenSurveysLocal = {};
    try{
      seenSurveysLocal = localUser.settings && localUser.settings.surveys?JSON.parse(localUser.settings.surveys):{};
    }
    catch(e){
        console.log("Problem parsing surveys", e);
    }
    return seenSurveysLocal;
  };

  const markSeenSurvey = (id) => {
      if(!user){
        console.log("No user to mark survey as seen.");
        return false;
      }
      let seenSurveysLocal = loadSeenSurveys(user);
      seenSurveysLocal[id] = `${Date.now()}`;
      console.log({seenSurveysLocal});
      setSeenSurveys(seenSurveysLocal);
      return meFn({surveys: JSON.stringify(seenSurveysLocal)});
  };

  
  const showSurvey = async (freq=false) => {
    if (!user) {
      console.log("User is null, skipping survey.");
      return;
    }
    const frequency = freq?freq:surveyData.frequency??-1;
    //we prevent from showing the survey to 90% of the users and only show to 10%
    const shouldShowSurvey = Math.random() <= frequency;
    //console.log({surveyData, frequency, shouldShowSurvey});
    //if we randomly defines that the user should see the survey, mark it as seen
    if(!shouldShowSurvey){
        await markSeenSurvey(surveyData.flagName);
        //console.log(`marked survey ${surveyData.flagName} as seen.`);
        return false;
    }
    //now check if user hasn't seen survey yet and set it visible if not
    //console.log(seenSurveys, surveyData.flagName, user);
    if(!seenSurveys.hasOwnProperty(surveyData.flagName)){
        setVisible(true);
    }else{
      console.log("survey already seen, do not show");
    }
  };

  //hook to set 2 global functions in case we want to debug or execute cratesurvey from outside the component
  useEffect(() => {
    //resetSurveys is only for debugging...
    window.resetSurveys = async () => {
        await meFn({surveys: JSON.stringify({})});
        setVisible(false);
        setThankYou(false);
        setAnswered(0);
        setSurveyData(prev => {
            let newData = { ...prev };
            newData.questions.forEach(question => {
                question.answers.forEach(answer => {
                    answer.selected = false;
                });
            });
            return newData;
        });
        setSeenSurveys({});
    };
    window.crateSurveyShow = (freq=false) => {
      showSurvey(freq);
    };
  }, [user, seenSurveys]);
  useEffect(() => {
    if(!surveyData){
        return;
    }
    //console.log("new ansert coming");
    let answered = surveyData.questions.reduce((acc, question) => {
        return acc + question.answers.filter(answer => answer.selected).length;
    }, 0);
    //console.log("Answered:", answered);
    if (answered === surveyData.questions.length){
        //console.log("Enought answers, submitting survey.");
        handleSubmit();
        return;
    }
    //console.log("Missing answers: ", surveyData.questions.length - answered);
  }, [surveyData]);


  useEffect(() => {
    if (!user){
      return;
    }
    let seenSurveys = loadSeenSurveys(user);

    setSeenSurveys(seenSurveys);

  }, [user]);

  useEffect(() => {
    if (!user){
      return;
    }
    if(isGettingAssetAvailability){
      return;
    }
    if(assetsAvailable < user.assetsPerDay){
      const timeoutId = setTimeout(() => {
        showSurvey();
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [user, assetsAvailable, isGettingAssetAvailability]);

  return (
    <div className={`${s.survey} ${visible?s.visible:''}`}>
        <div className={`${s.shadow}`} onClick={shadowClick}/>
        <div className={`${s.modal}`}>
            <span className={`${s.close} `} onClick={closeClick}>X</span>
            {!thankYou && surveyData.questions.filter((q, qIndex) => qIndex === answered ).map((question, qIndex) =>
            <React.Fragment key={qIndex}>
                <div className={`${s.question} ${s.accent} m-b-16`}>{question.labelAccent}</div>
                <div className={`${s.question} m-b-16`}>{question.label}</div>
                {question.answers.map((answer, aIndex) => 
                    <div key={`question-1-answer-${aIndex}`} id={`question-1-answer-${aIndex}`} className={`${s.answer} m-b-8`} onClick={() => submitAnswer(qIndex, aIndex)}>{answer.label}</div>
                )}
            </React.Fragment>
            )}
            {thankYou && 
            <>
                <div className={`${s.thankYou} ${s.accent} m-b-16`}>{thankYou}</div>
                <div className={`${s.cta}`} onClick={closeClick}>Continue</div>
            </>
            }
        </div>
    </div>
  );
};

export default Survey;
