import React, {useEffect, useRef, useState, useImperativeHandle, forwardRef} from 'react';
import { useUserContext } from "../../contexts";
import CATEGORIES from './menu.json';

import './Menu.scss';

const Menu = ({visible, closeCallback, external, newUrlScheme}, ref) => {
    
    const {user} = useUserContext();

    const floatingMenuRef = useRef(null);
    
    const [floatingMenuExpand, setFloatingMenuExpand] = useState(null);

    const [currentPath, setCurrentPath] = useState(null);
    
    //NEW MENU LOGIC
    const contentMenuRef = useRef(null);
    const [showMenuContent, setShowMenuContent] = useState(false);
    const [contentMenu, setContentMenu] = useState(false);
    const [menuLeft, setMenuLeft] = useState(0);
    const [menuWidth, setMenuWidth] = useState(0);

    const baseHref = external?'https://user.productioncrate.com':'';

    useEffect(() => {
        if(floatingMenuExpand === null || !visible || !floatingMenuRef || !floatingMenuRef.current){
            return;
        }
        //console.log(floatingMenuExpand)
        window.scrollTo(0, 0);
        floatingMenuRef.current.scrollTo({ top: 0, behavior: 'smooth' });

    }, [floatingMenuExpand]);

    useEffect(() => {
        initCurrentPath();
    }, []);

    const initCurrentPath = () => {
        let path = window.location.pathname;
        if (path === '' || path === '/') {
            path = 'home'
        }

        if (!CATEGORIES.mainCategories.some(m => path.includes('/'+m.id))) {
            path = null;
        }
        setCurrentPath(path);
    };
      
    const replaceDomainsLoggedIn = (str, loggedIn, vertical, category, subcategory) => {
        if (newUrlScheme && vertical !== 'plugins' && category !== 'plugins') {
            let uriPath = 'https://app.productioncrate.com';
            
            if (vertical === 'home' && !category){
                return uriPath;
            }
            if(vertical && category && subcategory && category === 'search') {
                uriPath += `/search/${subcategory}?main_category=${vertical}`;
                return uriPath;
            }
            if (vertical) {
                vertical.replace('home', '');
                uriPath += `/${vertical}`;
            }
            if (category) {
                uriPath += `/${category}`;
            }
    
            if (subcategory) {
                uriPath += `/${subcategory}`;
            }
    
            return uriPath;
            //return mapOldUrlSchemeToNew(str);
        }
        let finalUrl = !loggedIn?str:str.replace('rendercrate.com', 'productioncrate.com')
                        .replace('graphicscrate.com', 'graphics.productioncrate.com')
                        .replace('footagecrate.com', 'vfx.productioncrate.com')
                        .replace('soundscrate.com', 'sfx.productioncrate.com');
        if (finalUrl === 'https://rendercrate.com/3D') {
            finalUrl = 'https://rendercrate.com/';
        }
        if (finalUrl === 'https://productioncrate.com/3D') {
            finalUrl = 'https://productioncrate.com/3D/';
        }
        return finalUrl;
    };


    const renderMenuContent = (mainCat, user) => {
        let column = [];
        let menuColumns = [];
        let menuCounter = 0;
        let maxRows;

        if (window.innerWidth < 1024) {
            maxRows = 20;
        }else{
            maxRows = 18;
        }
        mainCat.categories.forEach((cat, i) => {
            if (!cat.subcategories) {
                cat.subcategories = [];
            }

            if ((menuCounter + 1 + cat.subcategories.length) < maxRows) {
                column.push(cat);
                menuCounter += (1+cat.subcategories.length);
            } else {
                menuColumns.push([...column]);
                menuCounter = 0;
                column = [];
                column.push(cat);
                menuCounter += (1+cat.subcategories.length);
            }
            if (i === mainCat.categories.length-1) {
                menuColumns.push([...column]);
            }
        });

        //console.log(menuColumns);
        return (
            menuColumns.filter(cols => cols.length>0).map((cols, k) =>
                <div  className={`content_wrapper fade-in`} key={`menu-column-${k}`}>
                    {cols.map((category, j) => 
                        <div 
                            className="menu_lol" 
                            key={`mainCategory-${showMenuContent}-${k}-categories-${j}-${category.id}`}
                        >
                            {category.link &&
                            <a className="title" href={replaceDomainsLoggedIn(category.link, user, mainCat.id, category.id)}>
                                {category.name} 
                                <img src={`${baseHref}/images/icon.external_link.svg`} className="icon" />
                            </a>
                            }
                            {!category.link &&
                            <span className="title">
                                {category.name}
                            </span>
                            }
                            {category.subcategories && category.subcategories.length>0 && 
                            <div className={`submenu`}>
                                {category.subcategories.map((subcategory, h) =>
                                <a href={replaceDomainsLoggedIn(subcategory.link, user, mainCat.id, category.id, subcategory.id)} className="submenuItem" key={`mainCategory-${showMenuContent}-${k}-categories-${j}-${category.id}-submenu-${h}`}>
                                    {subcategory.name}
                                    {subcategory.tag &&
                                    <span className="tag">{subcategory.tag}</span>
                                    }
                                </a>
                                )}
                            </div>
                            }
                        </div>
                    )}
                </div>
            )
        );
    };


    return (
        <>
        <div className="big_menu" data-main-menu="1" ref={ref}
            onMouseLeave={() => {
                setContentMenu(false);
                setShowMenuContent(false);
                setMenuWidth(0);
                //console.log("MOUSE LEFT");
                initCurrentPath();
            }}
        >
            <div className="top_menu" >
                {CATEGORIES.mainCategories.map((mainCategory, i) =>
                <div 
                    key={`key-main_category-${i}=${mainCategory.id}`} 
                    className={`menu_heading ${showMenuContent===mainCategory.id?'selected':''} `} 
                    onMouseEnter={({currentTarget}) => {
                        const element = currentTarget.querySelector('[data-title]');
                        const parentWithPosition = element.closest('[data-main-menu]');
                        //console.log(parentWithPosition);
                        const elementRect = element.getBoundingClientRect();
                        const parentRect = parentWithPosition.getBoundingClientRect();

                        // Calcula la posición X relativa al abuelo
                        const positionX = elementRect.left - parentRect.left;
                        const leftPositionMenu = positionX + (element.getBoundingClientRect().width)/2;
                        //console.log('Posición X respecto al abuelo:', positionX);
                        //console.log('Ancho del title:', element.getBoundingClientRect().width);
                        //console.log('Position little guy:', leftPositionMenu);

                        setContentMenu(true);
                        setShowMenuContent(mainCategory.id);
                        setMenuLeft(leftPositionMenu);
                        setMenuWidth(element.getBoundingClientRect().width);

                        setCurrentPath('/'+mainCategory.id);
                    }}
                >
                    <a 
                        className={`title ${!currentPath || currentPath.includes('/'+mainCategory.id)?'active':'disabled'}`} 
                        data-title={mainCategory.name} 
                        data-alternate={mainCategory.alternate??mainCategory.name} 
                        href={replaceDomainsLoggedIn(mainCategory.link, user, mainCategory.id)}
                    >
                        <span>{mainCategory.name}</span>
                    </a>
                </div>
                )} 
            </div> 
            <div className="little_guy" style={{left: `${menuLeft}px`, width: `${menuWidth}px`}}></div>
            <div 
                className={` content_container visible
                    ${showMenuContent !== false && [0, 1, 2].includes(CATEGORIES.mainCategories.map(c => c.id).indexOf(showMenuContent))?'left-side':''} 
                    ${showMenuContent !== false && [CATEGORIES.mainCategories.length-1,CATEGORIES.mainCategories.length-2].includes(CATEGORIES.mainCategories.map(c => c.id).indexOf(showMenuContent))?'right-side':''}
                `} 
                ref={contentMenuRef}
                style={{
                    display: contentMenu?'flex':'none',
                    left: `${menuLeft}px`, 
                }}>
                
                {CATEGORIES.mainCategories.map((mainCategory, i) =>
                <React.Fragment key={`mainCategory-${showMenuContent}-categories-${i}`}>
                {showMenuContent===mainCategory.id &&
                    <>
                    {renderMenuContent(mainCategory, user)}
                    </>
                }
                </React.Fragment>
                )}
            </div>     
        </div>
        <div className={`floating_menu ${visible?'visible':''}`} ref={floatingMenuRef}>
            <div className="barrier"></div>
            <span onClick={closeCallback} className={`close glyphicon glyphicon-cross`} ></span>
            {CATEGORIES.mainCategories.map((mainCategory, i) =>
                <React.Fragment key={`mainCategory2-${showMenuContent}-categories-${i}`}>
                    <span className={`title ${floatingMenuExpand === i?'visible':''}`} onClick={() => setFloatingMenuExpand(prev => prev ===null || prev !== i?i:null)} >{mainCategory.name} <span className="glyphicon glyphicon-down"></span></span>
                    <div className={`list ${floatingMenuExpand === i?'visible':''}`}>
                    {mainCategory.categories.map((category, k) =>
                        <React.Fragment key={`mainCategory3-${showMenuContent}-categories-${i}-${k}-${category.id}`}>
                        {category.link &&
                        <a className="subtitle" href={replaceDomainsLoggedIn(category.link, user)}> {category.name} <img src={`${baseHref}/images/icon.external_link.svg`} className="icon" /></a>
                        }
                        {!category.link &&
                        <span className="subtitle" > {category.name}</span>
                        }
                        {category.subcategories?.map((subcategory, j) =>
                            <a href={replaceDomainsLoggedIn(subcategory.link, user)} className="link" key={`mainCategory-${showMenuContent}-categories-${i}-${category.id}-submenu-${j}`}>
                                - {subcategory.name}
                                {subcategory.tag &&
                                <span className="tag">{subcategory.tag}</span>
                                }
                            </a>
                        )}
                        </React.Fragment>
                    )}
                    </div>
                </React.Fragment>
            )}
        </div>
        </>
    );
};


export default forwardRef(Menu);

